import React, { FC, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from 'src/components/common/Button';
import { CSLoader } from 'src/components/common/CsLoader';
import { Typography } from 'src/components/common/Typography';
import { icons } from 'src/configs/icons';
import './styles.scss';

type Props = {
  handleBack: () => void;
  promotionPageInfo: any;
  handleRedirect: (attributes: any) => () => void;
};

const PromotionInfoPage: FC<Props> = ({ handleBack, handleRedirect, promotionPageInfo }) => {
  const { t }: Translation = useTranslation();
  const [iframeHeight, setIframeHeight] = useState<any>(0);
  const [isLoading, setIsLoading] = useState(true);

  const banner = useMemo(() => {
    if (!!promotionPageInfo?.banner) {
      return promotionPageInfo.banner;
    }

    return null;
  }, [promotionPageInfo]);

  const buttonActions = promotionPageInfo?.buttonActions;
  const { target, targetParam } = buttonActions ?? {};

  const handleIframeLoad = (): void => {
    setIsLoading(false);
  };

  useEffect(() => {
    window.addEventListener('message', (event) => {
      if (event.data.type === 'iframeHeight') {
        setIframeHeight(event.data.height);
      }
    });
  }, []);

  return (
    <div className="promotions-info-page">
      <Button
        onClick={handleBack}
        className="promotions-info-page__back-button-wrapper"
        icon={icons.controlPrev}
        justifyContentValue="flex-start"
      >
        <span>{t('backToPromotion')}</span>
      </Button>
      <div className="promotions-info-page__description-wrapper">
        {!!banner && !promotionPageInfo?.iframeSrc && (
          <img className="promotions-info-page__description-img" src={banner} alt={t('descriptionImage')} />
        )}
        <div className="promotions-info-page__description-title-text-button-wrapper">
          <Typography
            variant={'h6'}
            children={
              <span
                className="promotions-info-page__description-title"
                dangerouslySetInnerHTML={{ __html: promotionPageInfo.title }}
              />
            }
          />
          {promotionPageInfo?.iframeSrc ? (
            <>
              {isLoading && (
                <div className="loader-container">
                  <CSLoader speed="slow" />
                </div>
              )}
              <iframe
                onLoad={handleIframeLoad} // Handle iframe load
                style={{ height: iframeHeight || 'initial', display: isLoading ? 'none' : 'block' }} // Hide iframe while loading
                src={promotionPageInfo?.iframeSrc}
                className="promo_frame"
              ></iframe>
            </>
          ) : (
            <Typography
              variant={'h6'}
              children={
                <span
                  className="promotions-info-page__description-text"
                  dangerouslySetInnerHTML={{ __html: promotionPageInfo.longDescription }}
                />
              }
            />
          )}
          {buttonActions && target && targetParam && (
            <div className="promo-btn">
              <Button
                key={target}
                onClick={handleRedirect(buttonActions)}
                className="promotions__button promotions__button--description success-filled-btn"
              >
                {t(`goTo${targetParam}`) ?? `Go To ${targetParam}`}
              </Button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default PromotionInfoPage;
