import accounting from 'accounting';
import moment from 'moment';
import Coins from 'src/components/common/Coins';
import VisualCurrencyIcon from 'src/components/common/VisualCurrencyIcon';
import { GameParamType } from 'src/types/req-dto/game';
import {
  CURRENCY_SYMBOLS,
  PAYMENT_TYPES,
  SPORT_CATEGORIES,
  THEMES,
  XPRESS_PROVIDER_WITH_REDIRECT_HOME,
} from '../utils/constants';

const isValidEmail = (email: string): boolean => {
  //  eslint-disable-next-line
  return /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
    email
  );
};

const detectDevice = (): 'desktop' | 'mobile' | 'tablet' => {
  const ua = navigator.userAgent;

  if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
    return 'tablet';
  } else if (
    /Mobile|Android|iP(hone|od)|IEMobile|BlackBerry|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(ua)
  ) {
    return 'mobile';
  }

  return 'desktop';
};

const getExtension = (fileName: string): string => {
  const splittedFilaName = fileName.split('.');

  if (splittedFilaName[0].length === fileName.length) {
    return 'font';
  }

  return splittedFilaName[splittedFilaName.length - 1];
};

const getSkinId = (): number => {
  const dynamicSkinId = sessionStorage.getItem('dynamicClubAppSkinId');
  return Number(dynamicSkinId ?? process.env.REACT_APP_SKIN_ID ?? 0);
};

const getThemeOfBlog = (): Record<string, string> => {
  const themes = {
    Usdt: {
      '--background-surface-1': '#11111c',
      '--background-surface-2': '#2d2d3a',
      '--button-primary': '#28c000',
      '--button-primary-hover': '#219d00',
      '--button-primary-text': '#ffffff',
      '--text-primary': '#ffffff',
    },
    Carnaval: {
      '--background-surface-1': '#0d081e',
      '--background-surface-2': '#1C133A',
      '--button-primary': '#ffbf17',
      '--button-primary-hover': '#ffcc45',
      '--button-primary-text': '#ffffff',
      '--text-primary': '#ffffff',
    },
    PapiGames: {
      '--background-surface-1': '#1C2026',
      '--background-surface-2': '#2B313B',
      '--button-primary': '#FE6500',
      '--button-primary-hover': '#C65308',
      '--button-primary-text': '#FFFFFF',
      '--text-primary': '#FFFFFF',
    },
  };

  const webSiteName = process.env.REACT_APP_WEBSITENAME as string;
  return themes[webSiteName] || themes['PapiGames'];
};

const isTokenExpirate = (): boolean => {
  const storedDate = localStorage.getItem('storedDate');

  if (storedDate) {
    const currentDate = new Date().valueOf();
    return Boolean(storedDate) && currentDate - Number(storedDate) > 0;
  }

  return false;
};

const getField = (values: any[], fieldName: string): any =>
  values.find((targetObj: any) => targetObj.hasOwnProperty(fieldName));

const getCurrencySymbol = (currency: string): string => CURRENCY_SYMBOLS[currency] || '';

const formatCurrency = (
  amount: number,
  currency: string,
  currencySymbol?: string,
  buyin?: boolean,
  t?: any
  // eslint-disable-next-line sonarjs/cognitive-complexity
): string | JSX.Element => {
  if (Boolean(process.env.REACT_APP_VISUAL_CURRENCY_ICON)) {
    return <VisualCurrencyIcon amount={buyin ? -amount : amount} />;
  }
  if ((currency === 'FPP' || currency === 'GOS') && amount >= 0) {
    return <Coins coin={buyin ? -amount : amount} type={currency} />;
  }

  const _getCurrencySymbol = (): string => {
    if (currencySymbol) {
      return currencySymbol + ' ';
    } else {
      if (currency === 'GoS') {
        return t('gos_points') + ' ';
      } else {
        return getCurrencySymbol(currency) + ' ';
      }
    }
  };
  if (amount === 0) {
    return _getCurrencySymbol() + amount;
  }
  if (envBoolean(process.env.REACT_APP_FORMATTED_AMOUNT_WITH_DOT as string)) {
    return accounting
      .formatMoney(buyin ? -amount : amount, _getCurrencySymbol(), {
        precision: 0,
      })
      .replace(/,/g, '.');
  } else {
    return accounting.formatMoney(buyin ? -amount : amount, _getCurrencySymbol());
  }
};

const convertAllTagsArr = (
  categoriesData: any,
  selectedCategory: string,
  homeTag: any,
  tagsMetaInfo: [] = [],
  lng: string
): any => {
  if (tagsMetaInfo) {
    const updatedMetaInfo = tagsMetaInfo.map((item: any) => ({
      ...item,
      tagTrName: item.translations[lng] || item.tag,
      tagLabel: item?.configs?.label || '',
    }));
    categoriesData?.[selectedCategory]?.tags?.forEach(
      (category: { tagName: string; viewType: string; priority: string; tagTrName: string; tagLabel: string }) => {
        updatedMetaInfo.forEach((tagInfo: Record<string, string>) => {
          if (category.tagName === tagInfo.tag) {
            category.tagTrName = tagInfo.tagTrName;
            category.viewType = tagInfo.viewType;
            category.priority = tagInfo.priority;
            category.tagLabel = tagInfo.tagLabel;
          }
        });
      }
    );
  }

  const tagsNames: string[] = [];
  const specialTagsNames: any = [];

  if (homeTag?.hasHomeTag) {
    tagsNames.push('home');
  }

  const arr =
    categoriesData?.[selectedCategory]?.tags
      ?.sort(
        (a: { tagName: string; priority: number }, b: { tagName: string; priority: number }) =>
          b?.priority - a?.priority
      )
      ?.map((f: { tagName: string; tagTrName: string }) => f?.tagTrName || f?.tagName) || [];
  tagsNames.push(...arr);

  return {
    allTagNames: tagsNames,
    allTagData: {
      ...categoriesData[selectedCategory],
      tags: [
        ...specialTagsNames,
        ...(categoriesData[selectedCategory]?.tags
          ? categoriesData[selectedCategory]?.tags?.sort(
              (a: { tagName: string; priority: number }, b: { tagName: string; priority: number }) =>
                b?.priority - a?.priority
            )
          : []),
      ],
    },
  };
};

const getRowNumber = (str: string): string => {
  switch (str) {
    case 'oneRow': //TODO correct viewType word from back
      return '2';
    case 'twoRow': //TODO correct viewType word from back
      return '1';
    default:
      return '1';
  }
};

const setParams = (
  user: any,
  game: any,
  skinId: number,
  language: string,
  isMobile: boolean,
  walletId?: string
): any => {
  const params: GameParamType = {
    token: user?.token ?? '',
    id: game?.id,
    userId: user ? user?.id : null,
    skinId,
    walletId,
    lang: language,
    device: isMobile ? 'mobile' : 'desktop',
    lobbyUrl: window?.location?.href ? window.location.href : window.origin,
  };

  if (
    (isMobile && ['Vivo'].includes(game?.provider)) ||
    ['Endorphina', 'Booming'].includes(game?.provider) ||
    (game?.integrator === 'xpress' && XPRESS_PROVIDER_WITH_REDIRECT_HOME.includes(game?.provider))
  ) {
    params.lobbyUrl = location.origin.toString();
  }

  return params;
};

//need to refactor with the help of backend
const getTransactionData = (paymentData: any, user: any, paymentType: any, locale: string): any => {
  const { _id, method, methodId, withRedirection, depositUseHomeUrl, withdrawUseHomeUrl } = paymentData;

  const transactionData: any = {};

  transactionData.transactionType = PAYMENT_TYPES[paymentType];
  transactionData.language = locale;
  transactionData.paymentId = _id;
  transactionData.method = method;
  transactionData.methodId = methodId;
  transactionData.withRedirection = withRedirection;

  if (user) {
    transactionData.playerId = user.id;
  }

  if ((window && transactionData.transactionType === 'deposit') || transactionData.transactionType === 'DEPOSIT') {
    transactionData.returnUrl = window.origin;
  }

  if (paymentType === 'deposit' && depositUseHomeUrl) {
    transactionData.redirectURL = window.origin;
    transactionData.depositUseHomeUrl = true;
  }

  if (withdrawUseHomeUrl) {
    transactionData.redirectURL = window.origin;
  }

  return transactionData;
};

const onInactive = (cb: () => void, resetCb: () => void, inactivitiTime: number): void => {
  let wait = setTimeout(cb, inactivitiTime);

  document.onmousedown =
    window.onmouseenter =
    window.onkeypress =
    window.onmousemove =
    window.onload =
      function () {
        resetCb();
        clearTimeout(wait);
        wait = setTimeout(cb, inactivitiTime);
      };
};

const isTokenExpired = (exp: number): boolean => {
  const now = moment();
  return now.valueOf() > exp;
};

const disableInputNumberSymbols = (e: any, type: string, disableNumberSymbols: boolean | undefined): void => {
  if (type === 'number' && ['e', 'E', '+', '-', '.'].includes(e.key) && disableNumberSymbols) {
    e.preventDefault();
  }
};

const groupPayments = (payments: PaymentMethod[]): any => {
  const _group = payments.reduce((paymentGroup: any, method: any) => {
    if (method.hasOwnProperty('group')) {
      (paymentGroup[method['group']] = paymentGroup[method['group']] || []).push(method);
    }
    return paymentGroup;
  }, {});

  if (!!Object.keys(_group).length) return _group;

  return null;
};

const allTagChecker = (array: string[]): string[] | null => {
  if (!Array.isArray(array)) return null;
  return array.length > 0 ? ['all', ...array] : array;
};

const getRewards = (
  personalInfo: any,
  referralLinks: ReferralLinks | null,
  money: any
): { cash: number | string | JSX.Element; spins: number | string } => {
  const notParticipationValue = personalInfo?.referralId || referralLinks?.referralId ? 0 : '-';
  const currency = money ? Object.keys(money).filter((f) => f !== 'GOS')[0] : '';
  const spins = (money.FPP || money.GOS) ?? notParticipationValue;
  const cash = money[currency] ? formatCurrency(money[currency], currency) : notParticipationValue;

  return {
    cash,
    spins,
  };
};

const scrollToTop = (): void => {
  setTimeout(() => {
    const srollablrConatainer = document?.getElementById('scroll_container') as HTMLIFrameElement;

    try {
      (srollablrConatainer ?? window).scroll({
        behavior: 'smooth',
        top: 0,
      });
    } catch (err) {
      if (err instanceof TypeError) {
        window.scroll(0, 0);
      } else {
        throw err;
      }
    }
  }, 0);
};

const replaceAll = (str: string, oldClass: string, newClass: string): string => {
  return str.replace(new RegExp(oldClass, 'g'), newClass);
};

const setBodyOverflow = (type: 'set' | 'unset'): void => {
  const _fixed = 'fixed-body ';

  document.body.className =
    type === 'unset'
      ? _fixed + replaceAll(document.body.className, _fixed, '')
      : document.body.className.replace(_fixed, '');
};

// index strated from 0;
const getFromPath = (path: string, separator: string, index = 0): string => {
  if (path.split(separator)[index]) {
    return path.split(separator)[index].includes('?')
      ? path.split(separator)[index].split('?')[0]
      : path.split(separator)[index];
  } else {
    return '';
  }
};

const isJsonString = (str: string): boolean => {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
};

const getFromQueryParams = (search: string): { [key: string]: string } => {
  const newStr = search.slice(1);
  const arr = newStr.split('&');
  return arr.reduce((acc, cur) => {
    const d = cur.split('=');
    return {
      ...acc,
      [d[0]]: d[1],
    };
  }, {});
};

const getMyColor = (cssString: string, value: string): string => {
  const keyValuePairs = cssString.split(';').filter((pair) => pair.trim() !== '');
  const main3Value = keyValuePairs.find((pair) => pair.includes(value));

  if (main3Value) {
    const value = main3Value.split(':')[1];
    console.log(value);
    return value;
  } else {
    console.log('Значение для --main-3 не найдено.');
    return '';
  }
};

const getCssObject = (cssString: string): object => {
  const keyValuePairs = cssString.split(';').filter((pair) => pair.trim() !== '');

  return keyValuePairs.reduce((acc, pair): object => {
    const [key, value] = pair.split(':');
    acc[key.trim()] = value.trim();
    return acc;
  }, {});
};

const getDocumentNotFoundTranslation = (params: any, translationKey: string): string => {
  const paramsArray = params && Object.keys(params);

  if (['document_not_found', 'hierarchy_permission_error'].includes(translationKey)) {
    return translationKey + '_affiliateId';
  }

  if (paramsArray.includes('email')) {
    return translationKey + '_email';
  } else if (paramsArray.includes('nationalId')) {
    return translationKey + '_nationalId';
  } else if (paramsArray.includes('signupIp')) {
    return translationKey + '_signupIp';
  } else {
    return 'regFailureText';
  }
};

const sliceIntoChunks = (arr: [], chunkSize: number): any => {
  const res = [];
  for (let i = 0; i < arr.length; i += chunkSize) {
    const chunk = arr.slice(i, i + chunkSize);
    res.push(chunk);
  }
  return res;
};

const makeQueryPath = (obj: { [key: string]: string }): string => {
  const res: string = Object.keys(obj).reduce((acc, cur) => `${acc}${cur}=${obj[cur]}&`, '?');
  return res.endsWith('&') ? res.substring(0, res.length - 1) : res;
};

const envBoolean = (envVar: any): boolean => {
  const arg: any = Boolean(envVar);
  return /true/.test(arg);
};

const getFreeSpinMessages = (arr: []): any[] => {
  return arr?.filter((m: any) => {
    if (m.templateId === 'gameboxPrize' && m.data?.mission?.prize?.type === 'freespins') {
      return m;
    }
  });
};

const getBaseUrl = (
  url: string | { baseURL: string; path: string },
  params: any,
  joinQueryArrayValues: any
): string => {
  let path = '';

  if (typeof url === 'string') {
    path = url;
  } else {
    path = url.baseURL + url.path;
  }

  path = params ? path + '?' + joinQueryArrayValues : path;

  return path;
};

// eslint-disable-next-line
const isPaymentDataValid = (
  selectedPayment: any,
  paymentFields: any,
  t: any,
  wallets: any,
  setPaymentError: any,
  paymentError: any
): boolean => {
  const fields = selectedPayment?.fields ?? {};
  const errors = {};

  // eslint-disable-next-line
  Object.keys(fields).map((i) => {
    if (fields[i]?.isRequred && !paymentFields?.[i]) {
      errors[i] = t('payment_errors.required_field');
    }

    if (
      fields[i].type === 'number' &&
      fields[i].validation?.range &&
      paymentFields[i] &&
      paymentFields[i] > fields[i].validation?.range.max
    ) {
      errors[i] = t('payment_errors.amount_less_than_max') + fields[i].validation?.range.max;
    }

    if (
      fields[i].type === 'number' &&
      fields[i].validation?.range &&
      paymentFields[i] < fields[i].validation?.range.min
    ) {
      errors[i] = t('payment_errors.amount_greater_than_min') + fields[i].validation?.range.min;
    }

    if (i === 'amount' && selectedPayment?.action === 'withdraw' && paymentFields[i] > wallets?.[0].balance) {
      errors[i] = t('payment_errors.balance_amount');
    }

    if (fields[i].type === 'string' && paymentFields[i] && paymentFields[i].length > fields[i].validation?.range.max) {
      errors[i] = `Length must be less than ${fields[i].validation?.range.max}`;
    }

    if (fields[i]?.addedFields) {
      fields[i]?.addedFields[paymentFields[i]] &&
        Object.keys(fields[i]?.addedFields[paymentFields[i]]).map((m) => {
          if (fields[i].addedFields[paymentFields[i]][m].isRequred && !paymentFields[m] && m !== 'subMethod') {
            errors[m] = t('payment_errors.required_field');
          }
        });
    }
  });

  setPaymentError({ ...paymentError, ...errors });
  return Boolean(errors && !Object.keys(errors)?.length);
};

const configsVariablesHandler = (palette: { [key: string]: string }): void => {
  let palleteStyle = '';

  if (isJsonString('palette')) {
    palette = JSON.parse(palette as any);
  }

  Object.keys(palette).forEach((key: string) => {
    return (palleteStyle += `${key}:${palette[key]};`);
  });
  const storagePalette = localStorage.getItem('websitePalette');
  if (storagePalette) {
    document.getElementsByTagName('HTML')[0].setAttribute('style', storagePalette);
  } else {
    document.getElementsByTagName('HTML')[0].setAttribute('style', palleteStyle);
  }
};

const mobileClassListHandler = (isMobileState: boolean, selectedCategory: string): any => {
  if (document && isMobileState && SPORT_CATEGORIES.includes(selectedCategory)) {
    const headerEl = document.getElementsByClassName('top-nav-v2')[0];
    const footerEl =
      document.getElementsByClassName('footer-v2')[document.getElementsByClassName('footer-v2').length - 1];
    const contentEl = document.getElementsByClassName('page')[0];
    const containerEl = document.getElementsByClassName('layout--mobile')[0];
    const novusIframeEl = document.getElementsByClassName('sport-book-page')[0];
    const body = document.getElementsByTagName('body')[0];

    headerEl?.classList.add('iosMobileHeader');
    footerEl?.classList.add('iosMobileFooter');
    contentEl?.classList.add('iosMobilePage');
    containerEl?.classList.add('iosMobileContainer');
    novusIframeEl?.classList.add('iosIframe');
    body?.classList.add('iosBody');

    return () => {
      headerEl?.classList.remove('iosMobileHeader');
      footerEl?.classList.remove('iosMobileFooter');
      contentEl?.classList.remove('iosMobilePage');
      containerEl?.classList.remove('iosMobileContainer');
      novusIframeEl?.classList.remove('iosIframe');
      body?.classList.remove('iosBody');
    };
  }
};

const reachTextParser = (str: string): any => <div dangerouslySetInnerHTML={{ __html: str }} />;

const htmlParse = (c: string, url: string): any => {
  let content = c.replace(/<img\s+[^>]*src=["']([^"']+)["'][^>]*>/gi, `<img src="${url}$1" />`);
  content = content.replace(/<svg\s+[^>]*src=["']([^"']+)["'][^>]*>/gi, `<svg src="${url}$1" />`);
  return content;
};

const isIOS = (): boolean => {
  return (
    ['iPad Simulator', 'iPhone Simulator', 'iPod Simulator', 'iPad', 'iPhone', 'iPod'].includes(navigator.platform) ||
    (navigator.userAgent.includes('Mac') && 'ontouchend' in document)
  );
};
const isColorDark = (rgbString: string): boolean => {
  const rgbValues = rgbString.match(/\d+/g);

  if (!rgbValues || rgbValues.length !== 3) {
    return false;
  }

  const normalizedR = parseInt(rgbValues[0]) / 255;
  const normalizedG = parseInt(rgbValues[1]) / 255;
  const normalizedB = parseInt(rgbValues[2]) / 255;

  const luminance = 0.2126 * normalizedR + 0.7152 * normalizedG + 0.0722 * normalizedB;

  return luminance <= 0.5;
};

const filterData = (data: any, searched: any): any => {
  const filtered: any[] = [];

  data.map((item: any) =>
    item.subCategories.map((subCat: any) =>
      Object.values(subCat).map((el: any) =>
        el.map((miniEl: any) => {
          if (miniEl.title.toLowerCase().includes(searched.toLowerCase())) {
            filtered.push(miniEl);
          }
        })
      )
    )
  );

  return filtered;
};
const truncateString = (str: string, length: number): string => {
  if (str.length <= length) {
    return str;
  } else {
    return str.slice(0, length) + '...';
  }
};

const getStrapiMediaUrl: any = () => {
  if (process.env.REACT_APP_FILE_STORAGE_ADDRESS?.endsWith('/')) {
    return process.env.REACT_APP_FILE_STORAGE_ADDRESS?.substring(
      0,
      process.env.REACT_APP_FILE_STORAGE_ADDRESS?.length - 1
    );
  } else {
    return process.env.REACT_APP_FILE_STORAGE_ADDRESS;
  }
};

const transformCategoryName = (menuItem: string): string => {
  let category;

  if (menuItem === 'betting') {
    category = 'sport';
  } else if (menuItem === 'tournament') {
    category = 'casinoTournament';
  } else {
    category = menuItem;
  }

  return category;
};

const hasChildren = (categoriesData: any, category: string, navigation: any): boolean => {
  return Boolean(
    categoriesData[category]?.tags?.length > 1 && navigation?.find((f: any) => f.name === category)?.hasHomeTag
  );
};

const getDefaultTheme = (): SelectOptionType => {
  const DEFAULT_PALLETE = process.env.REACT_APP_DEFAULT_PALLETE;
  if (DEFAULT_PALLETE) {
    return { label: DEFAULT_PALLETE, value: DEFAULT_PALLETE };
  }
  return { label: THEMES[0], value: THEMES[0] };
};

export {
  allTagChecker,
  configsVariablesHandler,
  convertAllTagsArr,
  detectDevice,
  disableInputNumberSymbols,
  envBoolean,
  filterData,
  formatCurrency,
  getBaseUrl,
  getCssObject,
  getCurrencySymbol,
  getDefaultTheme,
  getDocumentNotFoundTranslation,
  getExtension,
  getField,
  getFreeSpinMessages,
  getFromPath,
  getFromQueryParams,
  getMyColor,
  getRewards,
  getRowNumber,
  getSkinId,
  getStrapiMediaUrl,
  getThemeOfBlog,
  getTransactionData,
  groupPayments,
  hasChildren,
  htmlParse,
  isColorDark,
  isIOS,
  isJsonString,
  isPaymentDataValid,
  isTokenExpirate,
  isTokenExpired,
  isValidEmail,
  makeQueryPath,
  mobileClassListHandler,
  onInactive,
  reachTextParser,
  scrollToTop,
  setBodyOverflow,
  setParams,
  sliceIntoChunks,
  transformCategoryName,
  truncateString,
};
